import { observer } from "mobx-react";

import styles from "./questions.module.css";
import Question from "./Question";

function Questions() {
  const questions = [
    {
      title: "What is ClassX and how does it work?",
      content: [
        <p>
          <b>
            ClassX connects you to in-person learning opportunities in your
            city.
          </b>{" "}
          Leveraging the power of bottom-up organization with local hosts and
          expert instructors, we offer classes tailored to your community's
          needs.
        </p>,
      ],
    },
    {
      title: "Who can sign up as an instructor?",
      content: [
        <p>
          Anyone with expertise in a subject, relevant qualifications, strong
          communication skills, and a commitment to teaching can apply to become
          an instructor on ClassX. To get started, apply through our{" "}
          <a
            href="/register"
            className="text-primary-purple-400 underline cursor-pointer"
          >
            Instructor Signup Page
          </a>
          .
        </p>,
      ],
    },
    {
      title: "Who can sign up as a host?",
      content: [
        <p>
          <b>
            Anyone with a suitable venue and a commitment to supporting learning
            can sign up as a host on ClassX.
          </b>{" "}
          If you have space to accommodate in-person classes and want to
          facilitate education in your community, apply through our{" "}
          <a
            href="/register"
            className="text-primary-purple-400 underline cursor-pointer"
          >
            Host Signup Page.
          </a>
          .
        </p>,
      ],
    },
    {
      title: "How do I enroll in a course?",
      content: [
        <span>
          1. Create an Account: Sign up on{" "}
          <a
            href="/"
            className="text-primary-purple-400 underline cursor-pointer"
          >
            ClassX.com
          </a>
          .
        </span>,
        <span>
          2. Browse Classes: Find a topic that interests you in the location of
          your choice..
        </span>,
        <span>
          3. Select and Enroll: Click "Enroll Now" and complete the payment.
        </span>,
        <span>
          4. Start Learning: Access your course materials immediately after
          enrollment.
        </span>,
      ],
    },
    {
      title: "Can I cancel or reschedule a course?",
      content: [
        <span>
          Yes: <br />
          - Cancel: Full refund within 24 hours of enrollment. Partial refunds
          before the start date. <br />- Reschedule: Contact support or your
          instructor to adjust your schedule based on availability.
        </span>,
      ],
    },
    {
      title: "Are all courses in-person or are there online options?",
      content: [
        <p>
          ClassX offers in-person classes. Other formats that best suit your
          lifestyle and learning style when selecting a course like onlive live
          learning may be available.
        </p>,
      ],
    },
    {
      title: "How can I contact support if I need help?",
      content: [
        <p>You can reach ClassX support through:</p>,
        <p>
          - Email :{" "}
          <a
            href="mailto: support@classx.com"
            className="text-primary-purple-400 underline cursor-pointer"
          >
            support@classx.com
          </a>{" "}
          <br />- Live Chat: Available on our website during business hours{" "}
          <br />- Phone:{" "}
          <a
            href="tel: +1 (800) 123-4567"
            className="text-primary-purple-400 underline cursor-pointer"
          >
            +1 (800) 123-4567
          </a>{" "}
          (Mon-Fri, 9 AM - 5 PM EST) <br />- Help Center:{" "}
          <a
            href="https://blog.classx.tech/help"
            className="text-primary-purple-400 underline cursor-pointer"
            target="_blank"
          >
            ClassX Help Center
          </a>{" "}
          for FAQs and guides
        </p>,
      ],
    },
  ];

  return (
    <div className={styles.questions_content_content}>
      <div className="flex flex-col items-center gap-1.5 w-full text-center">
        <h4 className="text-body-large sm:text-heading lg:text-heading-medium font-body-large-bold sm:font-heading lg:font-heading-medium">
          <span className={styles.colored_span}>Questions?</span> Look here!
        </h4>
        <h3 className="text-body-medium sm:text-body-large font-body-medium sm:font-body-large">
          Some frequently asked questions answered for you
        </h3>
      </div>

      <div className={styles.questions_content_items}>
        {questions?.map((item: any, index: number) => (
          <Question key={index} question={item} />
        ))}
      </div>
    </div>
  );
}

export default observer(Questions);
