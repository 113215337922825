import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";

import styles from "../auth.module.css";
import { Input, Button } from "../../../components";
import React, { useEffect } from "react";
import {
  validatePassword,
  validateConfirmPassword,
} from "../../../utils/validation";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../store";

function ResetPassword() {
  const location = useLocation();
  const { userStore } = useStore();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = React.useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] =
    React.useState<string>("");
  const [isPasswordValid, setIsPasswordValid] = React.useState<boolean>(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] =
    React.useState<boolean>(false);

  const query = new URLSearchParams(location.search);
  const accessToken = query.get("access_token");

  useEffect(() => {
    if (!accessToken) {
      navigate("/");
    }
  }, [accessToken, navigate]);

  const handleResetPassword = async (e: any) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      const response = await userStore.resetPassword(accessToken!, newPassword);

      if (response) {
        toast.success(response);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      } else {
        toast.error("Error reseting password");
      }
    } catch (err: any) {
      if (err.response.status === 401) {
        toast.error("Reset password link has expired.");
      } else {
        toast.error("Error reseting password");
      }
    }
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewPassword(value);
    setIsPasswordValid(validatePassword(value));
    setIsConfirmPasswordValid(
      validateConfirmPassword(value, confirmNewPassword)
    );
  };

  const handleConfirmNewPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setConfirmNewPassword(value);
    setIsConfirmPasswordValid(validateConfirmPassword(newPassword, value));
  };

  return (
    <main className={styles.main}>
      <ToastContainer theme="dark" />
      <div className={styles.left_column}>
        <img
          src="/images/login-cover.png"
          alt="Login Cover"
          className={styles.cover_image}
        />
      </div>

      <div className={styles.right_column}>
        <div className="w-full h-full flex flex-col justify-center items-center space-y-6">
          <span className="text-[##4A4A4A] font-bold text-3xl">
            Reset your password
          </span>
          <form
            className="w-full p-[8rem] flex flex-col space-y-6"
            onSubmit={handleResetPassword}
          >
            <Input
              label="New password"
              type="password"
              value={newPassword}
              validation={validatePassword}
              onChange={handleNewPasswordChange}
            />
            <Input
              label="Confirm new password"
              type="password"
              value={confirmNewPassword}
              validation={(value) =>
                validateConfirmPassword(newPassword, value)
              }
              onChange={handleConfirmNewPasswordChange}
            />
            <Button
              type="primary"
              text="Reset"
              isDisabled={!isPasswordValid || !isConfirmPasswordValid}
              submit={true}
            />
          </form>
        </div>
      </div>
    </main>
  );
}

export default observer(ResetPassword);
