import { Navigate, createBrowserRouter } from "react-router-dom";
import Login from "./screens/auth/Login";
import Register from "./screens/auth/Register";
import CompleteRegister from "./screens/auth/CompleteRegister";
import ChoosePlan from "./screens/auth/ChoosePlan";
import Home from "./screens/root/Home";
import Search from "./screens/root/Search";
import Course from "./screens/root/Course";
import Cohort from "./screens/root/Cohort";
import Courses from "./screens/root/Courses";
import MyProfile from "./screens/authed/MyProfile";
import Payment from "./screens/authed/payment";
import Enrolled from "./screens/authed/Enrolled";
import Verifcation from "./screens/auth/Verifcation";
import ForgotPassword from "./screens/auth/forgotPassword";
import ResetPassword from "./screens/auth/resetPassword";
import InstructorUpgrade from "./screens/authed/InstructorUpgrade";
import HostUpgrade from "./screens/authed/HostUpgrade";
import MySessions from "./screens/authed/MySessions";
import Teaching from "./screens/authed/Teaching";
import ClassroomsManagement from "./screens/authed/ClassroomsManagement";
import NewCourse from "./screens/authed/NewCourse";
import NewSession from "./screens/authed/NewSession";
import NewClassroom from "./screens/authed/NewClassroom";
import Rate from "./screens/authed/Rate";
import Favorites from "./screens/authed/Favorites";
import PaymentFailed from "./screens/authed/payment/PaymentFailed";
import PostWirePaymentSuccess from "./screens/authed/postWirePaymentSuccess";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/search",
    element: <Courses />,
  },
  {
    path: "/courses",
    element: <Courses />,
  },
  {
    path: "/course/:id",
    element: <Course />,
  },
  {
    path: "/cohort/:id",
    element: <Cohort />,
  },
  {
    path: "/payment/:id",
    element: <Payment />,
  },
  {
    path: "/enrolled",
    element: <Enrolled />,
  },
  {
    path: "/successfully-enrolled",
    element: <PostWirePaymentSuccess />,
  },
  {
    path: "/user/validation",
    element: <Verifcation />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/complete-register",
    element: <CompleteRegister />,
  },
  {
    path: "/choose-plan",
    element: <ChoosePlan />,
  },
  {
    path: "/instructorUpgrade",
    element: <InstructorUpgrade />,
  },
  {
    path: "/hostUpgrade",
    element: <HostUpgrade />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/user/forgotPassword/reset",
    element: <ResetPassword />,
  },
  {
    path: "/myprofile",
    element: <MyProfile />,
  },
  {
    path: "/mysessions",
    element: <MySessions />,
  },
  {
    path: "/teaching",
    element: <Teaching />,
  },
  {
    path: "/newCourse",
    element: <NewCourse />,
  },
  {
    path: "/session/:type/:id?",
    element: <NewSession />,
  },
  {
    path: "/classroomsManagement",
    element: <ClassroomsManagement />,
  },
  {
    path: "/newClassroom",
    element: <NewClassroom />,
  },
  {
    path: "/rate/:id",
    element: <Rate />,
  },
  {
    path: "/favorites",
    element: <Favorites />,
  },
  {
    path: "/paymentFailed/:sessionId",
    element: <PaymentFailed />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

export default router;
