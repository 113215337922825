import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";

import styles from "../auth.module.css";
import { Input, Button } from "../../../components";
import React from "react";
import { validateEmail } from "../../../utils/validation";
import { useStore } from "../../../store";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = React.useState<string>("");
  const { userStore } = useStore();
  const navigate = useNavigate();

  const sendForgotPasswordRequest = async () => {
    try {
      const res = await userStore.forgotPassword(email);

      if (res) {
        toast.success(res);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      } else {
        toast.error("An error occured while sending the reset password email");
      }
    } catch (error: any) {
      toast.error("Email not found/Error sending reset link");
    }
  };

  return (
    <main className={styles.main}>
      <ToastContainer theme="dark" />
      <div className={styles.left_column}>
        <img
          src="/images/login-cover.png"
          alt="Login Cover"
          className={styles.cover_image}
        />
      </div>

      <div className={styles.right_column}>
        <div className="w-full h-full flex flex-col justify-center items-center space-y-6">
          <span className="text-[##4A4A4A] font-bold text-3xl">
            Reset your password
          </span>
          <div className="w-full p-[8rem] flex flex-col space-y-6">
            <Input
              label="Email"
              value={email}
              validation={validateEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="primary"
              text="Reset"
              onClick={() => sendForgotPasswordRequest()}
              isDisabled={!validateEmail(email)}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default observer(ForgotPassword);
