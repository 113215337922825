import React, { useEffect, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import { useGoogleLogin } from "@react-oauth/google";
import env from "../../../config/env";

import styles from "../auth.module.css";
import { useStore } from "../../../store";
import { Input, Button } from "../../../components";
import { validateEmail, validatePassword } from "../../../utils/validation";

function Login() {
  const LinkedInApi = {
    clientId: env.LINKEDIN_CLIENT_ID,
    redirectUrl: env.LINKEDIN_REDIRECT_URL,
    oauthUrl: env.LINKEDIN_OAUTH_URL,
    scope: env.LINKEDIN_SCOPE,
    state: env.LINKEDIN_STATE,
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userStore } = useStore();

  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  const [redirectionUrl, setRedirectionUrl] = React.useState<string>("/");

  const isLinkedInAuthRef = useRef<boolean>(false);

  const verificationStatusParam = searchParams.get("verificationStatus");
  const redirectionUrlParam = searchParams.get("redirectionUrl");

  const hasShownToast = useRef(false);

  useEffect(() => {
    if (!hasShownToast.current) {
      if (verificationStatusParam) {
        if (verificationStatusParam === "success") {
          toast.success(
            "Your email has been successfully verified. You can login to your account"
          );
        } else {
          toast.error(
            "Something went wrong while verifying your email. Please try again later."
          );
        }
        hasShownToast.current = true;
      }

      if (redirectionUrlParam) {
        redirectionUrlParam === "home"
          ? setRedirectionUrl("/")
          : setRedirectionUrl(`/${redirectionUrlParam}`);
      }
    }

    if (window.opener && window.opener !== window) {
      const code = getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ type: "code", code: code }, "*");
      window.close();
    }
    window.addEventListener("message", handlePostMessage);
    return () => window.removeEventListener("message", handlePostMessage);
  }, [verificationStatusParam, redirectionUrlParam]);

  const handlePostMessage = (event: any) => {
    if (event.data.type === "code" && !isLinkedInAuthRef.current) {
      isLinkedInAuthRef.current = true;
      const { code } = event.data;
      handleLinkedInSuccess(code);
    }
  };

  const getCodeFromWindowURL = (url: any) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get("code");
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleLogin(tokenResponse),
  });

  const handleSubmit = async () => {
    userStore
      .login(email.trim(), password)
      .then((token) => {
        if (token && token !== null) {
          navigate(redirectionUrl);
        } else {
          toast("Invalid email or password!");
        }
      })
      .catch((error) => {
        if (
          error.response.data.error.code === "LOGIN_FAILED_EMAIL_NOT_VERIFIED"
        ) {
          toast.error(
            "Email not verified. Please check your inbox for verification link."
          );
        } else {
          toast.error("Invalid email or password!");
        }
      });
  };

  const handleGoogleLogin = async (response: any) => {
    try {
      const token = response.access_token;
      const res = await userStore.loginWithGoogle(token);

      if (res) {
        navigate("/");
      } else {
        toast("Google login failed!");
      }
    } catch (error: any) {
      console.log("error", error);

      toast(error.response.data.error.message);
    }
  };

  const handleLinkedInSuccess = async (code: any) => {
    try {
      const linkedinToken = code;
      const res = await userStore.loginWithLinkedIn(linkedinToken);
      if (res) {
        navigate("/");
      } else {
        toast("LinkedIn Sign in failed!");
      }
    } catch (error: any) {
      toast(error.response.data.error.message);
    }
  };

  const showPopup = () => {
    const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    const oauthUrlComplete = `${oauthUrl}&client_id=${encodeURIComponent(
      clientId!
    )}&scope=${encodeURIComponent(
      scope!
    )}&state=${state}&redirect_uri=${redirectUrl}`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrlComplete,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  return (
    <main className={styles.main}>
      <ToastContainer theme="dark" />
      <div className={styles.left_column}>
        <img
          src="/images/login-cover.png"
          alt="Login Cover"
          className={styles.cover_image}
        />
      </div>

      <div className={styles.right_column}>
        <div className={styles.right_column_content}>
          <Link className={styles.back_button} to="/">
            <img
              src="/images/icons/arrow-left.svg"
              alt="Arrow Left"
              width={5}
              height={10}
            />
            <span className={styles.back_text}>Home</span>
          </Link>

          <span className={styles.sub_title}>Hop back in!</span>

          <div className={styles.form}>
            <Input
              label="Email"
              value={email}
              validation={validateEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              label="Password"
              type="password"
              value={password}
              validation={validatePassword}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Link to="/forgot-password">
              <span className={styles.forgot_password_link}>
                Forgot your password?
              </span>
            </Link>

            <Button
              type="primary"
              text="Sign in"
              onClick={() => handleSubmit()}
              isDisabled={!validateEmail(email) || !validatePassword(password)}
            />
          </div>

          <div className={styles.separator}>
            <hr className={styles.separator_line} />
            <span className={styles.separator_text}>Or continue with</span>
            <hr className={styles.separator_line} />
          </div>

          <div className={styles.social_buttons}>
            <Button
              type="tertiary"
              text="Continue with Google"
              onClick={() => googleLogin()}
              icon="/images/icons/google-colored.svg"
            />
            <Button
              type="tertiary"
              text="Continue with Linkedin"
              onClick={showPopup}
              icon="/images/icons/linkedin-colored.svg"
            />
          </div>

          <div className={styles.footer}>
            <span className={styles.footer_text}>
              Don&apos;t have an account?{" "}
            </span>
            <Link to="/register">
              <span className={styles.footer_link}>Sign Up</span>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default observer(Login);
