import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";

interface LanguageDropdownProps {
  language: string;
  currency: string;
  setLanguage: (lang: string) => void;
  setCurrency: (cur: string) => void;
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  language,
  currency,
  setLanguage,
  setCurrency,
}) => {
  const [dropdown, setDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const langsRef = useRef<HTMLDivElement>(null);

  const languages = [
    { id: "EN", value: "English" },
    { id: "FR", value: "Français" },
  ];
  const currencies = ["USD", "EUR", "CAD", "TND"];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        langsRef.current &&
        !langsRef.current.contains(event.target as Node)
      ) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDropdown]);

  return (
    <div
      className={
        "relative flex items-center gap-1.5 text-sm font-semibold cursor-pointer " +
        (dropdown
          ? "text-primary-paradise-pink-500"
          : "text-primary-purple-800")
      }
      ref={langsRef}
      onClick={() => setDropdown(!dropdown)}
    >
      <span>{language}</span>
      <span>•</span>
      <span>{currency}</span>
      <img
        src={
          dropdown
            ? "/images/icons/arrow-up.svg"
            : "/images/icons/arrow-down-purple.svg"
        }
        alt=""
      />
      {dropdown && (
        <div
          className="absolute top-12 bg-white rounded-xl"
          style={{ boxShadow: "0px 4px 10px -2px #00000024" }}
          ref={dropdownRef}
        >
          <div className="flex justify-between w-full">
            <div className="w-full">
              <h3 className="text-base font-bold text-primary-paradise-pink-850 bg-primary-purple-50 py-2 px-5 border-b border-primary-gray-200 rounded-tl-xl">
                Language
              </h3>
              <ul className="list-none flex flex-col gap-3 text-sm font-normal py-3 px-5">
                {languages.map((lang, index) => (
                  <li
                    key={index}
                    className={`cursor-pointer py-0.5 ${
                      language === lang.id
                        ? "text-primary-paradise-pink-500"
                        : "text-primary-paradise-pink-850"
                    }`}
                    onClick={() => setLanguage(lang.id)}
                  >
                    {lang.value}
                  </li>
                ))}
              </ul>
            </div>

            <div className="w-full">
              <h3 className="text-base font-bold text-primary-paradise-pink-850 bg-primary-purple-50 py-2 px-5 border-b border-primary-gray-200 rounded-tr-xl">
                Currency
              </h3>
              <ul className="list-none flex flex-col gap-3 text-sm font-normal py-3 px-5">
                {currencies.map((cur, index) => (
                  <li
                    key={index}
                    className={`cursor-pointer py-0.5 ${
                      currency === cur
                        ? "text-primary-paradise-pink-500"
                        : "text-primary-paradise-pink-850"
                    }`}
                    onClick={() => setCurrency(cur)}
                  >
                    {cur}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(LanguageDropdown);
