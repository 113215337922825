"use client";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";

import styles from "../auth.module.css";
import { Button } from "../../../components";
import { useStore } from "../../../store";
import "react-international-phone/style.css";
import { ConfirmationPopup } from "../../../components";

function CompleteRegister() {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();

  const [chosenPlanId, setChosenPlanId] = React.useState<number>(1);
  const [isPopupOpen, setIsPopupOpen] = React.useState<boolean>(false);
  const [isRegisterClicked, setIsRegisterClicked] =
    React.useState<boolean>(false);

  const plans = [
    {
      id: 1,
      title: "Student Plan",
      description:
        "If you are a student you wll be able to participate to our sessions.",
    },
    {
      id: 2,
      title: "Instructor Plan",
      description:
        "If you are an instructor you have to complete your profile and you will be able to publish sessions.",
    },
    {
      id: 3,
      title: "Host Plan",
      description:
        "If you are a host you have to complete your profile and you will be able to publish classrooms.",
    },
  ];

  useEffect(() => {
    if (userStore?.user !== null) {
      navigate("/");
    } else if (!userStore?.userData) {
      navigate("/register");
    }
  }, []);

  const handlePlan = async (planId: number) => {
    if (planId == chosenPlanId && userStore.userData) {
      const user = userStore.userData;
      if (planId == 1) {
        userStore.setUserData({ ...user, plan: "student" });
        navigate("/complete-register");
      } else if (planId == 2) {
        setIsRegisterClicked(true);
        userStore.setUserData({ ...user, plan: "instructor" });
        toast("Please wait until we register your account");
        await registerAccount("instructorUpgrade", 2);
      } else {
        setIsRegisterClicked(true);
        userStore.setUserData({ ...user, plan: "host" });
        toast("Please wait until we register your account");
        await registerAccount("hostUpgrade", 3);
      }
    } else {
      setChosenPlanId(planId);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleConfirmation = () => {
    setChosenPlanId(3);
  };

  const registerAccount = async (redirectionUrl: string, step: number) => {
    const user = {
      email: userStore?.userData?.email?.trim() || "",
      fullName: userStore?.userData?.fullName || "",
      password: userStore?.userData?.password || "",
      state: userStore?.userData?.state || "",
      goal: userStore?.userData?.goal || "",
      country: userStore?.userData?.country || "",
      governorate: userStore?.userData?.country || "",
      phone: userStore?.userData?.phone || "",
      gender: userStore?.userData?.gender || "",
      situation: userStore?.userData?.situation || "",
      interest: userStore?.userData?.interest || [],
      step: step,
    };

    userStore
      .register(user)
      .then(async (token) => {
        if (token && token !== null) {
          toast.success(
            "Your account is created successfully. A verification link has been sent to you via Email."
          );
          setTimeout(() => {
            setIsRegisterClicked(false);
            userStore.setUserData({});
            navigate(`/login?redirectionUrl=${redirectionUrl}`);
          }, 5000);
        } else {
          setIsRegisterClicked(false);
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        setIsRegisterClicked(false);
        toast.error("Something went wrong!");
      });
  };

  return (
    <main className={styles.main}>
      <ToastContainer theme="dark" />

      <div className={styles.left_column}>
        <img
          loading="lazy"
          src="/images/login-cover.png"
          alt="Login Cover"
          className={styles.cover_image}
        />
      </div>

      <div className={styles.right_column}>
        <div className={styles.right_column_content}>
          <Link className={styles.back_button} to="/">
            <img
              src="/images/icons/arrow-left.svg"
              alt="Arrow Left"
              width={5}
              height={10}
            />
            <span className={styles.back_text}>Home</span>
          </Link>

          <span className={styles.title}>Welcome Onboard</span>
          <span className={styles.sub_title}></span>

          <div className={styles.plans}>
            {plans.map((plan) => (
              <div className={styles.plan} key={plan.id}>
                <div className={styles.plan_details}>
                  <div className={styles.plan_title}>{plan.title}</div>
                  <div className={styles.plan_description}>
                    {plan.description}
                  </div>
                </div>
                <div className={styles.plan_button}>
                  <Button
                    width="130px"
                    type={plan.id == chosenPlanId ? "secondary" : "primary"}
                    text={plan.id == chosenPlanId ? "Continue" : "Upgrade"}
                    onClick={() => handlePlan(plan.id)}
                    isDisabled={isRegisterClicked}
                  />
                </div>
              </div>
            ))}
          </div>

          <ConfirmationPopup
            title="Upgrade to Host Plan"
            text="To become a host you must add a classroom"
            isOpen={isPopupOpen}
            close={closePopup}
            confirm={handleConfirmation}
          />
        </div>
      </div>
    </main>
  );
}

export default observer(CompleteRegister);
