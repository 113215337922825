import React from "react";

type SearchInputProps = {
  placeholder: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  value,
  setValue,
}) => {
  return (
    <div className="flex items-center gap-2 mt-2.5 px-5 py-4 bg-primary-purple-50 border border-primary-purple-200 rounded-xl">
      <img src="/images/icons/search-black.svg" alt="" />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="w-full bg-transparent focus:outline-none focus:ring-0"
      />
    </div>
  );
};

export default SearchInput;
