import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./becomeTeacher.module.css";
import { Button } from "src/components/shared";
import Carousel from "react-multi-carousel";

interface CustomDotProps {
  onClick?: () => void;
  active?: boolean;
}

const CustomDot: React.FC<CustomDotProps> = ({ onClick, active }) => {
  return (
    <button
      className={`custom-dot h-[3px] rounded-full mx-1.5 transition-all duration-300 ${
        active
          ? "active bg-primary-paradise-pink-800 w-16"
          : "bg-primary-gray-300 w-[60px]"
      }`}
      onClick={onClick}
    />
  );
};

const BecomeTeacher: React.FC = () => {
  const navigate = useNavigate();

  const testimonials = [
    {
      name: "Steve H. Kruger",
      profession: "Chief Product Manager, Intel",
      quote:
        "“Being a part of ClassX allowed me to reach students who are truly motivated to learn. I’ve seen so many grow and achieve things they never thought possible, and it’s exciting to know I’ve played a role in that.”",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      className="flex flex-col gap-5 sm:gap-9 lg:gap-[60px] p-4 sm:p-10 lg:px-20 lg:py-12 border border-black border-opacity-10 rounded-2xl"
      style={{ boxShadow: "0px 18px 51px -16px rgba(0, 0, 0, 0.03)" }}
    >
      <div className="flex flex-col lg:flex-row justify-between items-center">
        <div className="flex flex-col gap-3 mb-8 lg:m-0">
          <h2 className="text-4xl font-bold">
            Shape Careers by <br /> Teaching with us
          </h2>
          <p className="sm:w-3/5 mb-4">
            Teach what you know and inspire learners to achieve their career
            goals. With your skills, you’ll help shape the next generation of
            professionals.
          </p>
          <div className="flex items-center gap-4">
            <Button
              type="primary"
              text="Teach with us"
              width="fit-content"
              onClick={() => navigate("/instructorUpgrade")}
            />
            <a
              href="https://community.classx.tech/teach-with-us"
              target="_blank"
              className="large-button text-primary-paradise-pink-500"
            >
              Learn more
            </a>
          </div>
        </div>

        <img src="/images/teachers.svg" />
      </div>

      <hr />

      <div className="lg:px-6">
        <Carousel
          responsive={responsive}
          autoPlay
          autoPlaySpeed={3000}
          showDots
          customDot={<CustomDot />}
        >
          {testimonials.map((item, index) => (
            <div
              key={index}
              className="flex flex-col sm:flex-row items-center gap-5 lg:gap-8 sm:px-[78px] mb-8"
            >
              <img
                src="images/steve@1x.png"
                className="h-full w-full fit-cover rounded-xl"
                srcSet="images/steve@1x.png 1x, images/steve@2x.png 2x, images/steve@3x.png 3x"
              />
              <div className="flex flex-col-reverse lg:flex-col gap-3.5 lg:gap-7">
                <p className="text-body-medium lg:text-body-large font-body-medium lg:font-body-large">
                  {item.quote}
                </p>
                <div className="text-center sm:text-start">
                  <p className="text-body-medium sm:text-body-small lg:text-body-medium font-body-medium-bold sm:font-body-small-bold lg:font-body-medium-bold text-primary-paradise-pink-800">
                    {item.name}
                  </p>
                  <p className="text-body-small lg:text-body-medium text-primary-paradise-pink-800">
                    {item.profession}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default BecomeTeacher;
