import React from "react";

import styles from "./steps.module.css";
import StepItem from "./StepItem";

const Steps: React.FC = () => {
  const steps = [
    {
      index: 1,
      title: "Join a Class",
      subTitle:
        "Browse a variety of courses across different industries and find a class that fits your goals.",
      icon: "/images/icons/steps/step1.svg",
      bgColor: "var(-Primary-PradisePink-50)",
      colored: false,
    },
    {
      index: 2,
      title: "Show up and Learn",
      subTitle:
        "Attend sessions near you and learn from experts who offer real-world, hands-on training.",
      icon: "/images/icons/steps/step2.svg",
      bgColor: "var(-Primary-PradisePink-50)",
      colored: false,
    },
    {
      index: 3,
      title: "Get Certified",
      subTitle:
        "Complete your sessions and graduate by earning an official certificate, boosting your career opportunities.",
      icon: "/images/icons/steps/step3.svg",
      bgColor: "var(-Primary-Purple-50)",
      colored: true,
    },
  ];
  return (
    <div className={styles.steps_container}>
      <div className={styles.steps}>
        <div className={styles.steps_list}>
          {steps.map((step) => (
            <StepItem
              key={step.index}
              title={step.title}
              subTitle={step.subTitle}
              icon={step.icon}
              index={step.index}
              colored={step.colored}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
